<template>
  <div class="card full-height bg-ligth">
    <div class="card-header">
      <h3 class="mb-0">
        {{ lista_campos != "" ? "Editar" : "Criar" }} Funcionário
      </h3>
    </div>
    <div class="card-body">
      <div class="col-md-12">
        <div class="form">
          <div class="row form-group">
            <div class="col-md-4">
              <label for="nome" class="col-md-12">Nome:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="nome"
                v-model="form.nome"
                placeholder="Digite o nome..."
              />
            </div>
            <div class="col-md-4">
              <label for="sobrenome" class="col-md-12">Sobrenome:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="sobrenome"
                v-model="form.sobrenome"
                placeholder="Digite o Sobrenome..."
              />
            </div>
            <div class="col-md-2">
              <label class="col-md-12">D. nascimento:*</label>
              <input
                required=""
                type="date"
                class="form-control"
                id="form_control_1"
                v-model="form.data_nascimento"
                placeholder="Data de nascimento..."
              />
            </div>
            <div class="col-md-2">
              <label for="cpf" class="col-md-12">CPF ou CNPJ:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="cpf"
                v-mask="variableWithMask"
                v-model="cpf_cnpj1"
                placeholder="Digite o cpf ou cnpj..."
              />
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-3 pull-left">
              <label for="telefone1">Telefone 1:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="telefone1"
                v-model="form.telefone1"
                placeholder="Digite o telefone/celular..."
              />
            </div>

            <div class="col-md-3 pull-left">
              <label>Profissão:*</label>
              <select
                required=""
                v-model="form.profissao_id"
                class="form-control"
              >
                <option></option>
                <option value="1">Cozinheira</option>
                <option value="2">Licenciado</option>
                <option value="3">Professor</option>
                <option value="4">Estudante</option>
                <option value="5">Engenheiro</option>
                <option value="6">financeiro</option>
                <option value="7">Gestor de convênios</option>
              </select>
            </div>

            <div class="col-md-3 pull-left">
              <label>Email:*</label>
              <input required v-model="form.email" class="form-control" />
            </div>
            <div  class="col-md-3 pull-left">
              <label>Login:*</label>
              <input required v-model="form.login" class="form-control" />
            </div>
          </div>

          <div class="row form-group">
            <h4 class="col-md-12">Endereço:</h4>
            <div class="col-md-2">
              <label class="col-md-12">CEP:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                v-model="form.cep"
                placeholder="cep ...."
                @input="set_cep()"
              />
            </div>
            <div class="col-md-5">
              <label class="col-md-12">Endereço:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                v-model="form.rua"
                placeholder="Rua ...."
              />
            </div>
            <div class="col-md-2">
              <label for="endereco_numero" class="col-md-12">Numero:*</label>
              <input
                type="text"
                class="form-control"
                id="endereco_numero"
                v-model="form.numero"
                placeholder="numero ...."
              />
            </div>
            <div class="col-md-3">
              <label for="endereco_complemento" class="col-md-12"
                >Complemento:</label
              >
              <input
                type="text"
                class="form-control"
                id="endereco_complemento"
                v-model="form.complemento"
                placeholder="Digite o complento..."
              />
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-4">
              <label for="endereco_bauirro" class="col-md-12">Bairro:*</label>
              <input
                required
                type="text"
                class="form-control"
                id="endereco_bauirro"
                v-model="form.bairro"
                placeholder="Digite o celular..."
              />
            </div>
          </div>

          <div class="row form-gro up">
            <div class="col-md-3">
              <label class="col-md-12">Perfil:*</label>
              <treeselect
                :multiple="false"
                :options="lista_perfil"
                placeholder="Selecione o Setor..."
                v-model="form.perfil_id"
              />
            </div>
            <div class="col-md-3">
              <label class="col-md-12">Setor:*</label>
              <treeselect
                :multiple="false"
                :options="lista_setor"
                placeholder="Selecione o Setor..."
                v-model="form.setor_id"
              />
            </div>

            <div class="col-md-12 pull-left">
              <label for="descricao" class="col-md-11 ml-2">Descricao:*</label>
              <textarea
                type="text"
                class="form-control"
                id="descricao"
                v-model="form.descricao"
                placeholder="Digite o descricao..."
              ></textarea>
            </div>
          </div>
          <div class="row form-group col-md-12">
            <div class="col-md-3 pull-left">
              <div class="md-radio-inline">
                <label>Status:*</label>
                <div class="space d-flex">
                  <b-form-radio :inline="true" value="1" v-model="form.status">
                    Ativo
                  </b-form-radio>

                  <b-form-radio :inline="true" value="2" v-model="form.status">
                    Desativado
                  </b-form-radio>

                  <b-form-radio :inline="true" value="3" v-model="form.status">
                    Suspenso
                  </b-form-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="form-group row">
          <div class="col-md-12 text-center">
            <button
              @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
              class="btn btn-primary"
              :disabled="verif"
            >
              Salvar
              <b-spinner
                v-show="verif"
                small
                variant="dark"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </button>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import UsuarioService from "@/core/services/usuario.service";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      form: {
        nome: null,
        sobrenome: null,

        cpf: null,
        data_nascimento: null,
        cep: null,
        rua: null,
        bairro: null,
        cidade_id: null,
        email: null,
        status: 1,
        profissao_id: 2,
        naturalidade_id: null,
        naturalidade_cidade: null,
        naturalidade_estado: null,
        naturalidade_pais: null,
        telefone1: null,
        telefone2: null,
        perfil_id: null,
        contrato_funcionario_id: 2,
        setor_id: null,
        login: null,

      },
      paramestado: "",
      value: "",
      loading: false,
      verif: false,
      variableWithMask: null,
      cpf_cnpj1: null,
    };
  },

  computed: {
    lista_usuarios() {
      return this.$store.state.sistema.lista_usuarios;
    },
    mensagem_alert() {
      return this.$store.state.configUsuarios.mensagem_alert;
    },

    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_usuario() {
      return this.$store.state.configUsuarios.lista_usuario;
    },
    cep() {
      return this.$store.state.configUsuarios.cep;
    },
    lista_setor() {
      return this.$store.state.setor.lista_setor.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Funcionarios" }]);
  },
  created() {
    this.listar_perfil();
    this.preenxerCampor();
  },
  watch: {
    cpf_cnpj1: function () {
      this.variableWithMask =
        this.cpf_cnpj1.replace(/[^0-9]/g, "").length <= 11
          ? "###.###.###-##"
          : "##.###.###/####-##";
      this.form.cpf = this.cpf_cnpj1.replace(/[^0-9]/g, "");
    },
  },
  methods: {
    async listar_perfil() {
      if (this.lista_campos == "") {
        await this.$store.dispatch(
          "configUsuarios/listar_usuario",
          UsuarioService.getUsuario()["usuario_id"]
        );
        console.log(this.$store.state.configUsuarios.lista_usuario);
      }
      await this.$store.dispatch("setor/listar_setor");
      await this.$store.dispatch("configEmpresa/listar_perfil").finally(() => {
        // this.$store.dispatch("atualizar", "");
      });
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un Perfil no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("configUsuarios/create_usuario", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "gerenFuncionarios",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("configUsuarios/update_usuario", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "gerenFuncionarios",
      });
    },

    async set_cep() {
      this.form.cep = this.form.cep.replace(/[^0-9]/g, "");
      if (this.form.cep.length == 8) {
        await this.$store.dispatch("configUsuarios/set_cep", this.form.cep);
      }

      this.form.rua = this.cep.logradouro;
      this.form.bairro = this.cep.bairro;
      this.form.complemento = this.cep.complemento;
    },
    async preenxerCampor() {
      // if (this.lista_campos != "")
      this.form = {
        id: this.lista_campos.id,
        nome: this.lista_campos.nome,
        sobrenome: this.lista_campos.sobrenome,
        nome_sobrenome: this.lista_campos.nome_sobrenome,
        data_nascimento: this.lista_campos.data_nascimento,
        cpf: this.lista_campos.cpf,
        email: this.lista_campos.email,
        status: this.lista_campos.status,
        profissao_id: this.lista_campos.profissao_id,
        cep: this.lista_campos.cep,
        rua: this.lista_campos.rua,
        bairro: this.lista_campos.bairro,
        cidade_id: this.lista_campos.cidade_id,

        naturalidade_pais_id: this.lista_campos.naturalidade_pais_id,
        naturalidade_estado: this.lista_campos.naturalidade_estado_id,
        estado: this.lista_campos.estado_id,
        pais_id: this.lista_campos.pais_id,

        naturalidade_id: this.lista_campos.naturalidade_id,
        telefone1: this.lista_campos.telefone1,
        telefone2: this.lista_campos.telefone2,
        perfil_id: this.lista_campos.perfil_id,
        setor_id: this.lista_campos.setor_id,

        numero: this.lista_campos.numero,
        descricao: this.lista_campos.descricao,
        login: this.lista_campos.login
      };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>